<template>
  <div class="itemBox">
    <div class="title">视频收入</div>
    <div class="box">
      <div class="qb">全部</div>
      <div class="amoutBox">
        <div class="amout">{{ income.totalIncome || 0 }}</div>
        <div class="shou" v-if="false">收获</div>
      </div>
      <div class="zr">
        昨日：

        <span>{{ income.totalPay || 0 }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../../store";
export default {
  mounted() {
    this.getDate();
  },
  data() {
    return {
      store: Store.state,
      income: {
        totalIncome: 0,
        totalPay: 0,
      },
    };
  },
  methods: {
    // 打赏总金额
    async getDate() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(
        `/videoReward/statistics/${this.store.user.userId}`
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      console.log(res.data);
      this.income = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  .title {
    /* 视频收入 */
    height: 26px;
    font-family: SourceHanSansCN-Medium;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0em;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #333333;
  }
  .box {
    /* 矩形 6 */
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
    padding: 22px 31px;
    box-sizing: border-box;
    .qb {
      /* 全部 */
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #666666;
    }
    .amoutBox {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .amout {
        /* 1,211 PSC */
        height: 43px;
        font-family: SourceHanSansCN-Bold;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 0em;
        color: #ff7d41;
      }
      .shou {
        /* 矩形 22 */

        width: 107px;
        height: 46px;
        line-height: 46px;
        border-radius: 5px;
        text-align: center;
        background: #ff7d41;
        /* 收获 */

        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #ffffff;
      }
      .zr {
        /* 昨日： */
        height: 20px;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #666666;
        span {
          /* +856 */
          height: 23px;
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #0a961d;
        }
      }
    }
  }
}
</style>
