<template>
  <div class="itemBox">
    <div class="avatar">
      <el-avatar
        shape="square"
        :size="50"
        src="https://polyjetclub-backend.polysmartchain.com/images/normal/866.png"
      ></el-avatar>
    </div>
    <div class="user">
      <div class="name">{{ it.nickName || "" }}</div>
      <div class="addr">{{ uti.shrink(it.address, 6) || "" }}</div>
    </div>
    <div class="videoTitle">{{ it.videoTitle || "" }}</div>
    <div class="income">
      <div class="num" v-if="it.direction == 1">+{{ it.amount || "" }}</div>
      <div class="num err" v-if="it.direction == 2">-{{ it.amount || "" }}</div>
      <div class="time">{{ uti.changeTime(it.updateTime) || "" }}</div>
    </div>
  </div>
</template>
<script>
import Store from "../../../store";
export default {
  components: {},
  props: {
    it: {
      type: Object,
    },
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      i: {
        address: "123456", //  用户地址	string
        amount: "123", //	打赏金额	integer
        direction: "1", //	方向 1.收入 2.支出	integer
        id: "456", //	id	integer
        nickName: "张三", //	用户昵称	string
        targetUserId: "456978", //	目标用户id	integer
        updateTime: [2022, 5, 24], //	操作时间	string
        userId: "987", //	用户id	integer
        videoTitle: "今晚打老虎", //	视频标题	string
      },
    };
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  display: flex;
  justify-content: space-between;
  height: 84px;
  align-items: center;
  border-bottom: 2px solid #d8d8d8;
  .user {
    margin-left: 16px;
    // flex: 1;
    .name {
      /* 金牌讲师 */

      height: 23px;
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #333333;
    }
    .addr {
      /* 0Xd4a65s4d56a4sd65sad889a7dsad4as56dasd */

      height: 20px;
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
  .videoTitle {
    padding: 0 50px;
    flex: 1;
    /* 视频标题 */

    height: 26px;
    font-family: SourceHanSansCN-Bold;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0em;
    color: #333333;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .income {
    text-align: right;
    .num {
      /* +800 */

      height: 23px;
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #0a961d;
    }
    .err {
      color: #ec5853;
    }
    .time {
      /* 2022/05/18 */

      height: 17px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
    }
  }
}
</style>
